import api from "@/service/api/Public";
import moment from "moment";
import {
  BToast
} from 'bootstrap-vue'

const importLanguage = (language) => {
  return import(`@/lang/${language}`);
}

const Confirm = {
  show: false,
  title: "請確認",
  msg: "",
  okFunc: () => {
    return true
  }
}

const state = {
  Loading: [],
  alert: [],
  Confirm: {
    show: false,
    title: "",
    msg: "",
    okFunc: () => {
      console.log("???");
    }
  },

  lang: {},

  PTemp: {
    appraisalClass: [],
    bigClass: [],
    smallClass: [],
    departure: [],
    country: [],
    city: [],
    postcode: [],
    currency: [],
    PClass: {
      unit: [],
      tourType: [{
        name: "出境團體旅遊",
        values: "outbound"
      }, {
        name: "入境團體旅遊",
        values: "inbound"
      }, {
        name: "國民旅遊",
        values: "domestic"
      }, {
        name: "機+酒",
        values: "flightHotel"
      }, {
        name: "票卷",
        values: "ticket"
      }]
    },
    appraisalType: [],
    pointType: [],
    language: [],
    timeZone: [],
    favoriteFolder: []
  },

  mainPointIcon: [{
    name: "景點",
    values: "point",
    icon: ['fas', 'location-dot']
  }, {
    name: "餐廳",
    values: "restaurant",
    icon: ['fas', 'utensils']
  }, {
    name: "住宿",
    values: "hotel",
    icon: ['fas', 'house']
  }, {
    name: "交通",
    values: "traffic",
    icon: ['fas', 'bus-simple']
  }, {
    name: "其他",
    values: "other",
    icon: ['fas', 'gears']
  }],

  mainTypeIcon: [{
    name: "全部",
    values: "all",
    icon: ['fas', 'globe']
  }, {
    name: "景點",
    values: "point",
    icon: ['fas', 'location-dot']
  }, {
    name: "餐廳",
    values: "restaurant",
    icon: ['fas', 'utensils']
  }, {
    name: "住宿",
    values: "hotel",
    icon: ['fas', 'house']
  }],

  pointIcon: {
    point: ['fas', 'location-dot'],
    restaurant: ['fas', 'utensils'],
    hotel: ['fas', 'house'],
    traffic: ['fas', 'bus-simple'],
    other: ['fas', 'gears'],
    '': ['fas', 'gears'],
  },

  moveTypeIcon: [{
    name: "遊覽車",
    values: "bus",
    icon: ['fas', 'van-shuttle']
  }, {
    name: "車",
    values: "car",
    icon: ['fas', 'car-side']
  }, {
    name: "步行",
    values: "walk",
    icon: ['fas', 'person-walking']
  }, {
    name: "飛機",
    values: "plane",
    icon: ['fas', 'plane']
  }, {
    name: "船",
    values: "ship",
    icon: ['fas', 'ship']
  }],

  moveIcon: {
    plane: ['fas', 'plane'],
    car: ['fas', 'car-side'],
    bus: ['fas', 'van-shuttle'],
    ship: ['fas', 'ship'],
    walk: ['fas', 'person-walking']
  },

  imageModel: [{
    name: "一大五小(上)",
    values: "model1"
  }, {
    name: "一大五小(下)",
    values: "model2"
  }, {
    name: "一大五小(左)",
    values: "model3"
  }, {
    name: "一大五小(右)",
    values: "model4"
  }, {
    name: "五小(水平)(上)",
    values: "model5"
  }, {
    name: "五小(水平)(下)",
    values: "model6"
  }, {
    name: "五小(垂直)(上)",
    values: "model7"
  }, {
    name: "五小(垂直)(下)",
    values: "model8"
  }],

  groupTemp: [{
    name: "無",
    values: ""
  }, {
    name: "A",
    values: "A"
  }, {
    name: "B",
    values: "B"
  }, {
    name: "C",
    values: "C"
  }, {
    name: "D",
    values: "D"
  }, {
    name: "E",
    values: "E"
  }, {
    name: "F",
    values: "F"
  }, {
    name: "G",
    values: "G"
  }]
};

const getters = {
  formatDate() {
    return (date) => {
      const weekday = moment(date).weekday();
      const temp = ["天", "一", "二", "三", "四", "五", "六"]
      return temp[weekday];
    }
  },

  formatValues() {
    return (option, values, field = "values") => {
      // option => [{ name: example, values: example }]
      if (values) {
        const temp = values.split(",");
        const vTemp = [];

        _.each(temp, (item, key) => {
          const index = _.findIndex(option, (items, keys) => {
            return items[field] == item;
          });

          if (index > -1) {
            vTemp.push(option[index].name);
          }
        });

        return vTemp.join("、");
      }

      return "";
    }
  },

  formatPointIcon(state) {
    return (values) => {
      return state.pointIcon[values];
    }
  },

  formatMoveIcon(state) {
    return (values) => {
      return state.moveIcon[values];
    }
  },

  formatCurrency() {
    return (values) => {
      return values.toString().replace(/\B(?=(\d{3})+$)/g, ",");
    }
  },

  formatUnit(state) {
    return (values) => {
      const unit = _.find(state.PTemp.PClass.unit, {
        values: values
      });

      return unit ? unit.name : "";
    }
  },

  formatAverage() {
    return (division, equation) => {
      const number = isNaN(parseInt(division)) ? 0 : parseInt(division);
      const eqNumber = isNaN(parseInt(equation)) ? 0 : parseInt(equation);
      if (number == 0 || eqNumber == 0) {
        return (0).toFixed(2);
      }

      return (number / eqNumber).toFixed(2);
    }
  },

  getEditStrokeIcon(state) {
    const temp = ["point", "restaurant", "hotel", "traffic", "other"];
    return _.filter(state.mainPointIcon, (item, key) => {
      return _.indexOf(temp, item.values) > -1;
    });
  }
};

const actions = {

  async insertPointImage({
    commit
  }, data) {
    const result = await api.insertPointImage(data);
    return result;
  },

  async insertSystemImage({
    commit
  }, data) {
    const result = await api.insertSystemImage(data);
    return result;
  },

  async saveFolder({
    commit
  }, data) {
    const result = await api.saveFolder(data);
    return result;
  },

  async getField({
    commit
  }, data) {
    const result = await api.getField(data.folder, data.page, data.pagenumber);
    return result;
  },

  async autoSave({
    commit
  }, data) {
    const result = await api.autoSave(data.table, data.data);
    return result;
  },

  async setMoveTime({
    commit
  }, data) {
    const result = await api.setMoveTime(data);
    return result;
  },

  getLanguage({
    commit,
  }, language) {
    importLanguage(language).then((data) => {
      commit("setLanguage", data.default);
    });
  },

  async getWebImage({
    commit
  }, website) {
    const result = await api.getWebImage(website);
    return result;
  },

  async getData({
    state,
    commit
  }, data) {
    const result = await api.getData(data.table, data.search);
    return result;
  },

  async getMulitData({
    state,
    commit
  }, search) {
    const result = await api.getMulitData(search);
    return result;
  },

  async getPublicData({
    dispatch,
    commit
  }) {
    const temp = [{
      table: "Pub.BigClass",
      pretable: "bigClass",
      page: 0,
      pagenumber: "all",
      orderBy: {
        name: "orders",
        order: "asc",
      },
      field: ["uid as values", "name", "PreCode as code"],
    }, {
      table: "Pub.SmallClass",
      pretable: "smallClass",
      page: 0,
      pagenumber: "all",
      orderBy: {
        name: "orders",
        order: "asc",
      },
      field: ["uid as values", "name", "PreCode as code", "proClass1 as bigClass"],
    }, {
      table: "Pub.Departure",
      pretable: "departure",
      page: 0,
      pagenumber: "all",
      orderBy: {
        name: "orders",
        order: "asc",
      },
      field: ["uid as values", "name", "code"],
    }, {
      table: "Pub.Country",
      pretable: "country",
      page: 0,
      pagenumber: "all",
      orderBy: {
        name: "orders",
        order: "asc",
      },
      field: ["uid as values", "name", "smallEng as code", "areaTag"],
    }, {
      table: "Pub.City",
      pretable: "city",
      page: 0,
      pagenumber: "all",
      orderBy: {
        name: "orders",
        order: "asc",
      },
      field: ["uid as values", "name", "code", "countryTag"],
    }, {
      table: "Pub.PostCode",
      pretable: "postcode",
      page: 0,
      pagenumber: "all",
      orderBy: {
        name: "orders",
        order: "asc",
      },
      field: ["uid", "area_name as name", "area_uid as code", "City"],
    }, {
      table: "Pub.Currency",
      pretable: "currency",
      page: 0,
      pagenumber: "all",
      orderBy: {
        name: "orders",
        order: "asc",
      },
      where: {
        raw: ["currency.rate1 > 0"]
      },
      field: ["symbol", "code", "name", "uid as values", "rate1"]
    }, {
      table: "Pub.PubClass",
      pretable: "PClass",
      page: 0,
      pagenumber: "all",
      where: {
        wherein: {
          func: ["unit", "tourType"]
        },
      },
      field: ["name", "values", "func"]
    }, {
      table: "Pub.AppraisalType",
      pretable: "appraisalType",
      page: 0,
      pagenumber: "all",
      orderBy: {
        name: "orders",
        order: "asc",
      },
      field: ["name", "values", "func"]
    }, {
      table: "Pub.ElementClass",
      pretable: "pointType",
      page: 0,
      pagenumber: "all",
      orderBy: {
        name: "orders",
        order: "asc",
      },
      field: ["name", "values", "func", "searchRule"]
    }, {
      table: "Pub.Language",
      pretable: "language",
      page: 0,
      pagenumber: "all",
      orderBy: {
        name: "orders",
        order: "asc",
      },
      field: ["name", "values"]
    }, {
      table: "Pub.timeZone",
      pretable: "timeZone",
      page: 0,
      pagenumber: "all",
      orderBy: {
        name: "timezone",
        order: "asc",
      },
      field: ["name", "uid as values", "timezone"]
    }, {
      table: "Pub.AppraisalType",
      pretable: "appraisalClass",
      page: 0,
      pagenumber: "all",
      orderBy: {
        name: "orders",
        order: "asc",
      },
      field: ["dataType", "name", "values"]
    }, {
      table: "Stroke.FavoriteFolder",
      pretable: "favoriteFolder",
      page: 0,
      pagenumber: "all",
      orderBy: {
        name: "orders",
        order: "asc",
      }
    }];

    const result = await dispatch("getMulitData", temp);

    if (result.status) {
      commit("setPublicData", result.result);
    }

    return result;
  },

  async saveFavoriteFolder({
    state,
    commit
  }, data) {
    const result = await api.saveFavoriteFolder(data);
    if (result.status) {
      commit("setFolder", result.result);
      commit("addAlert", {
        title: "新增狀態",
        color: "success",
        msg: "新增成功"
      });
    }
  },

  async moveField({
    state
  }, data) {
    const result = await api.moveField(data);
    return result;
  }
};

const mutations = {
  setFolder(state, data) {
    state.PTemp.favoriteFolder = data;
  },

  addFuncId(state, data) {
    if (data) {
      state.Loading.push(data);
    }
  },

  removeFuncId(state, data) {
    if (data) {
      const index = _.indexOf(state.Loading, data);

      if (index > -1) {
        state.Loading.splice(index, 1);
      }
    }
  },

  addAlert(state, data) {
    const toast = new BToast();
    toast.$bvToast.toast(data.msg, {
      toaster: "b-toaster-bottom-right",
      title: data.title,
      variant: data.color || "primary",
      autoHideDelay: data.time || 1000,
    });
  },

  setConfirm(state, data) {
    _.each(Confirm, (item, key) => {
      state.Confirm[key] = data[key] || item;
    });
  },

  setLanguage(state, data) {
    state.lang = data;
  },

  setPublicData(state, data) {
    _.each(data, (item, key) => {
      if (item.pretable == "PClass") {
        const groupTemp = _.groupBy(item.data, "func");
        _.each(groupTemp, (items, keys) => {
          state.PTemp.PClass[keys] = items;
        });
        return
      }

      if (item.pretable == "country") {
        item.data = _.map(item.data, (item, key) => {
          const temp = [];
          item.code ? temp.push(item.code) : null;
          item.name ? temp.push(item.name) : null;
          item.name = temp.join("-");
          return item;
        });
      }

      if (item.pretable == "pointType") {
        this.commit("Search/setSearchDataClass", item.data, {
          root: true
        });
      }

      state.PTemp[item.pretable] = item.data;
    });

    state.PTemp.currency = _.map(state.PTemp.currency, (item, key) => {
      const nameTemp = _.filter([item.symbol, item.name]);
      item.tName = nameTemp.join(" ");
      return item;
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
