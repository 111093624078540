import api from "@/service/api/Tour";

const state = {
  element: false,
  elementTemp: {},
  // ----- 預設資料 清空 state 用的 -----
  default: {
    elementTemp: {
      uid: null,
      eleType: "",
      name: "",
      ename: "",
      zipCode: "",
      address: "",
      description: "",
      descriptionDetail: "",
      websiteUrl: "",
      phone: "",
      iframe: "",
      mapUrl: "",
      rating: 0,
      images: [],
      productTemp: [],
    },

    productTemp: {
      tourGroup: {},
      tourGroupProduct: {},
      product: {},
      TravelProAirLine: [],
      tourSpecial: [],
      tourRemark: []
    },

    strokeTemp: [],

    editStrokeTemp: {
      mapShow: true,
      strokeShow: true,
      appraiseShow: true,
      dataSwitch: "stroke",
      coordinate: "",
      witchDay: 1,
      TravelDayList: "",
      dataType: "point",
      productType: "",
      source: "",
      sourceName: "",
      belongProduct: "",
      product: "",
      name: "",
      ename: "",
      context: "",
      country: "",
      postcode: "",
      location: "",
      cost: "",
      price: "",
      tcost: "",
      tprice: "",
      number: "",
      useNumber: "1",
      unit: "pax",
      unit2: "day",
      costRemark: "",
      priceRemark: "",
      currency: "TWD",
      rate: "1",
      lockPrice: false,
      timezone: "",
      gmt: "",
      imagemethod: "model3",
      group: "",
      images: [],
      stopTime: {
        hour: 0,
        min: 0
      },
    }
  },
  // ----- 預設資料 清空 state 用的 -----

  productTemp: {
    tourGroup: {},
    tourGroupProduct: {},
    product: {},
    TravelProAirLine: [],
    tourSpecial: [],
    tourRemark: []
  },

  strokeTemp: [],
  appraiseTemp: [],

  // ----- 編輯景點 -----
  editProduct: false,
  editStroke: {
    show: false,
    reload: () => {}
  },
  editStrokeTemp: {
    mapShow: true,
    strokeShow: true,
    appraiseShow: true,
    dataSwitch: "stroke",
    coordinate: "",
    witchDay: 1,
    TravelDayList: "",
    dataType: "point",
    productType: "",
    source: "",
    sourceName: "",
    belongProduct: "",
    product: "",
    name: "",
    ename: "",
    context: "",
    country: "",
    postcode: "",
    location: "",
    cost: "",
    price: "",
    tcost: "",
    tprice: "",
    number: "",
    useNumber: "1",
    unit: "pax",
    currency: "TWD",
    rate: "1",
    lockPrice: false,
    timezone: "",
    group: "",
    gmt: "",
    imagemethod: "model3",
    stopTime: {
      hour: 0,
      min: 0
    },
  },
  // ----- 編輯景點 -----
  editPoint: {
    show: false,
    default: false,
  },
  favorite: false,
  favoriteList: [],
  focusDay: 1,
}
const getters = {}

const actions = {
  // ----- 新增商品 -----
  async createProduct({
    state,
    commit
  }, data) {
    const result = await api.createProduct(data);
    return result;
  },

  // ----- 取得商品資訊 基本資料、費用資訊、交通資訊、行程特色、備註說明 -----
  async getProduct({
    state,
    commit
  }, uid) {
    const result = await api.getProduct(uid);
    commit("setProductTemp", state.default.productTemp);

    if (result.status) {
      commit("setProductTemp", result.result);
    }

    return result;
  },

  // ----- 取得行程 行程表、每日行程、景點行程、其他行程、行程圖片 -----
  async getStroke({
    state,
    commit
  }, uid) {
    const result = await api.getStroke(uid);
    commit("setStrokeTemp", state.default.strokeTemp);
    // commit("setAppraiseTemp", state.default.strokeTemp);
    commit("Map/setStrokeTemp", state.default.strokeTemp, {
      root: true
    });

    if (result.status) {
      commit("setStrokeTemp", _.cloneDeep(result.result));
      commit("setAppraiseTemp", _.cloneDeep(result.result))
      commit("Map/setStrokeTemp", _.cloneDeep(result.result), {
        root: true
      });
    }
    return result;
  },

  async saveDay({
    state,
    commit
  }, data) {
    const result = await api.saveDay(data);
    if (result.status) {
      commit("resetDayTemp", result.result);
    }
    return result;
  },

  async saveStroke({
    state,
    commit
  }, data) {
    const temp = {
      stroke: state.productTemp.tourGroupProduct.sourceStroke,
      data: data
    };

    const result = await api.saveStroke(temp);
    if (result.status) {
      commit("resetDayTemp", result.result);
    }
    return result;
  },

  async editStroke({
    state,
    commit
  }, data) {
    const temp = {
      stroke: state.productTemp.tourGroupProduct.sourceStroke,
      data: data
    };

    const result = await api.editStroke(temp);
    if (result.status) {
      commit("resetDayTemp", result.result);
    }
    return result;
  },

  async saveStopTime({
    state,
    commit
  }, data) {
    const temp = {
      stroke: state.productTemp.tourGroupProduct.sourceStroke,
      data: data
    };

    const result = await api.saveStopTime(temp);
    if (result.status) {
      commit("resetDayTemp", result.result);
    }
    return result;
  },

  async saveMoveTime({
    state,
    commit
  }, data) {
    const temp = {
      stroke: state.productTemp.tourGroupProduct.sourceStroke,
      data: data
    };

    const result = await api.saveMoveTime(temp);
    if (result.status) {
      commit("resetDayTemp", result.result);
    }
    return result;
  },

  async saveOrder({
    state,
    commit
  }, data) {
    const temp = {
      stroke: state.productTemp.tourGroupProduct.sourceStroke,
      data: data
    };

    const result = await api.saveOrder(temp);
    if (result.status) {
      commit("resetDayTemp", result.result);
    }
    return result;
  },

  async getElement({
    state,
    commit
  }, stroke) {
    commit("setElementTemp", state.default.elementTemp);

    let temp = {
      uid: null,
      eleType: stroke.dataType,
      name: stroke.name,
      ename: stroke.ename,
      zipCode: stroke.postcode,
      address: stroke.location,
      description: stroke.context,
      descriptionDetail: stroke.context,
      websiteUrl: "",
      phone: "",
      iframe: "",
      mapUrl: "",
      rating: 3.5,
      images: stroke.images,
      productTemp: [],
    };

    if (stroke.source) {
      const result = await api.getElement(stroke.source);

      if (result.status) {
        temp = result.result;
      }
    }

    commit("setElementTemp", temp);
  },

  async pointRecommend({
    state,
    commit
  }, uid) {
    const result = await api.pointRecommend(uid);
    if (result.status) {
      commit("Search/setRecommend", result.result, {
        root: true
      });
    }
    return result
  },

  async addFavorite({
    state,
    commit
  }, data) {
    const result = await api.addFavorite(data);
    if (result.status) {

    }
    return result
  },

  async batchEdit({
    state,
    commit
  }, data) {
    data.strokeUid = state.productTemp.tourGroupProduct.sourceStroke
    const result = await api.batchEdit(data);
    if (result.status) {
      commit("resetDayTemp", result.result);
    }
    return result
  },

  async createPoint({
    state
  }, data) {
    const result = await api.createPoint(data);
    return result
  },

  async updateStrokePrice({
    state
  }, data) {
    const result = await api.updateStrokePrice(data);
    return result
  },

  async editAppraise({
    state
  }, data) {
    const result = await api.editAppraise(data);
    return result
  },

  async editCustomPrice({
    state
  }, data) {
    const result = await api.editCustomPrice(data);
    return result;
  }
}

const mutations = {
  setEditPoint(state, data) {
    state.editPoint = data;
  },

  setFocus(state, data) {
    state.focusDay = data;
  },

  setFavoriteList(state, data) {
    const index = _.findIndex(state.favoriteList, {
      uid: data.uid
    });

    if (index > -1) {
      state.favoriteList.splice(index, 1);
      return;
    };

    state.favoriteList.push(data);
  },

  removeFavoriteList(state, data) {
    const index = _.findIndex(state.favoriteList, {
      uid: data.uid
    });

    if (index > -1) {
      state.favoriteList.splice(index, 1);
    }
  },

  clearFavoriteList(state) {
    state.favoriteList = [];
  },

  setFavorite(state, data) {
    state.favorite = data;
  },

  setElement(state, data) {
    state.element = data;
  },

  setElementTemp(state, data) {
    state.elementTemp = data;
  },

  setEditProduct(state, data) {
    state.editProduct = data;
  },

  setEditStroke(state, data) {
    state.editStroke = {
      show: data.show,
      reload: data.reload || function () {

      }
    };
  },

  setEditStrokeTemp(state, data) {
    state.editStrokeTemp = state.default.editStrokeTemp;
    state.editStrokeTemp = data;
  },

  setProductTemp(state, data) {
    state.productTemp = data;
  },

  setStrokeTemp(state, data) {
    state.strokeTemp = _.map(data, (item, key) => {
      item.dayTemp = _.map(item.dayTemp, (items, keys) => {
        items.dayTitleShow = false;
        items.strokeTemp = _.map(items.strokeTemp, (sitem, skey) => {
          sitem.showInformation = false;
          sitem.sourceInformation = false;
          sitem.chooseImage = 0;
          return sitem;
        });

        return items;
      });

      item.strokeOtherTemp = _.groupBy(item.strokeOtherTemp, "TravelDayList")
      return item;
    });
  },

  setAppraiseTemp(state, data) {
    state.appraiseTemp = [];

    const temp = _.first(data);

    _.each(temp.dayTemp, (item, key) => {
      _.each(item.strokeTemp, (items, keys) => {
        state.appraiseTemp.push(items);
      });
    });

    _.each(temp.strokeOtherTemp, (item, key) => {
      state.appraiseTemp.push(item);
    });
  },

  resetDayTemp(state, data) {
    const index = _.findIndex(state.strokeTemp, {
      uid: data.strokeTemp["uid"]
    });

    if (index > -1) {
      state.strokeTemp[index].strokeOtherTemp = _.groupBy(data.strokeTemp.strokeOtherTemp,
        "TravelDayList") || [];


      _.each(data.dayTemp, (item, key) => {
        const dayIndex = _.findIndex(state.strokeTemp[index].dayTemp, {
          uid: item.uid
        });

        if (dayIndex > -1) {
          _.each(state.strokeTemp[index].dayTemp[dayIndex], (items, keys) => {
            if (keys == "strokeTemp") {
              item[keys] = _.map(item[keys], (sitem, skey) => {
                const modify = _.find(state.strokeTemp[index].dayTemp[dayIndex]
                  .strokeTemp, {
                    uid: sitem.uid
                  });

                if (modify) {
                  sitem.showInformation = modify.showInformation;
                  sitem.sourceInformation = modify.sourceInformation;
                  sitem.chooseImage = modify.chooseImage;
                } else {
                  sitem.showInformation = false;
                  sitem.sourceInformation = false;
                  sitem.chooseImage = 0;
                }
                return sitem;
              });
            }

            state.strokeTemp[index].dayTemp[dayIndex][keys] = item[keys] || items;
          });
        }
      });
    }

    this.commit("Map/setStrokeTemp", _.cloneDeep(state.strokeTemp));
    this.commit("Tour/setAppraiseTemp", _.map(_.cloneDeep(state.strokeTemp), (item, key) => {
      let temp = [];
      _.each(item.strokeOtherTemp, (item, key) => {
        _.each(item, (items, keys) => {
          temp.push(items)
        });

      });
      item.strokeOtherTemp = temp;
      return item;
    }));
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
