import http from "../support";
export default {
  async getPoint(data) {
    return await http.get("Schedule/getPoint", {
      keywords: data
    }, {}, null, 0, false);
  },

  async getPointProduct(data) {
    return await http.get("Schedule/getPointProduct", {
      uid: data
    }, {}, null, 0, false);
  },

  async getPointImage(data) {
    return await http.get("Schedule/getPointImage", data, {}, null, 0, false);
  },

  async searchPoint(method, search) {
    return await http.post("Schedule/searchPoint/" + method, search);
  }
}
