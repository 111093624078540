<template>
	<b-modal v-model="show" size="sm" centered @ok="okFunc()" :header-class="['bg-main', 'text-white', 'px-3', 'py-2']" :footer-class="['p-2']">
		<template v-slot:modal-header>
			<div class="w-100 d-flex align-items-center">
				<span>{{ title }}</span>
			</div>
		</template>

		<template v-slot:default>
			<div v-html="msg"></div>
		</template>

		<template v-slot:modal-footer="{ ok, close }">
			<div class="w-100 d-flex align-items-center justify-content-around">
				<span class="text-success m-pointer px-2" @click="ok">
					<b-icon icon="check-lg"></b-icon>
				</span>

				<span class="text-danger m-pointer px-2" @click="close">
					<b-icon icon="x-lg"></b-icon>
				</span>
			</div>
		</template>
	</b-modal>
</template>

<script>
export default {
	data() {
		return {};
	},

	props: {
		model: {
			type: Boolean,
			default: false,
		},

		title: {
			type: String,
			default: "",
		},

		msg: {
			type: String,
			default: "",
		},
	},

	computed: {
		show: {
			get() {
				return this.$props.model;
			},
			set(data) {
				this.$emit("update:model", data);
			},
		},
	},

	methods: {
		okFunc() {
			this.$emit("okFunc");
		},
	},
};
</script>
