<template>
	<div class="editor" v-if="loaded">
		<Editor id="edit" v-model="editorValue" :init="init"></Editor>
	</div>
</template>

<script>
import tinymce from "tinymce/tinymce.js";
import "tinymce/skins/ui/oxide/skin.css";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/plugins/emoticons";
import "tinymce/plugins/emoticons/js/emojis.js";
import "tinymce/plugins/table";
import "tinymce/plugins/quickbars";
import "tinymce/plugins/link";
import "tinymce/plugins/lists";
import "tinymce/plugins/advlist";
import "tinymce-i18n/langs5/zh_TW.js";
import Editor from "@tinymce/tinymce-vue";

export default {
	components: {
		Editor,
	},

	props: {
		value: {
			type: String,
			default: "",
		},
		plugins: {
			type: [String, Array],
			default: "emoticons table link lists advlist",
		},
		toolbar: {
			type: [String, Array],
			default: () => {
				return [
					"bold italic underline strikethrough | alignleft aligncenter alignright |outdent indent | bullist numlist",
					"fontselect fontsizeselect forecolor backcolor emoticons link"

					// "alignleft aligncenter alignright |outdent indent | bullist numlist | link",
					// "fontselect | fontsizeselect | Formatting | forecolor | backcolor"
				];
			},
		},
	},

	data() {
		return {
			init: {
				language: "zh_TW",
				selector: "textarea",
				height: 300,
				quickbars_insert_toolbar: false,
				skin: false,
				menubar: false,
				branding: false,
				content_css: false,
				content_style: "p {font-family: '微軟正黑體'; font-size: 14px; margin: 0px}",
				font_formats: "微軟正黑體='微軟正黑體';新細明體='新細明體';標楷體='標楷體';",
				fontsize_formats: "12px 14px 16px 18px 20px 22px 24px 26px 28px 36px",
				plugins: this.plugins,
				toolbar: this.toolbar,
				toolbar_groups: {
					formatting: {
						tooltip: "Formatting",
						icon: "format",
						items: "bold italic underline strikethrough",
					},
				},
			},
			loaded: false,
			editorValue: this.value,
		};
	},

	mounted() {
		setTimeout(() => {
			tinymce.init({});
			this.loaded = true;
		}, 100);

		document.addEventListener("focusin", (e) => {
			if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
				e.stopImmediatePropagation();
			}
		});
	},

	watch: {
		value(newValue) {
			this.editorValue = newValue;
		},

		editorValue(newValue) {
			this.$emit("input", newValue);
		},
	},

	methods: {},
};
</script>
