<template>
	<div id="app">
		<router-view class="overflow-auto" />

		<div class="w-100 d-flex align-items-center justify-content-center position-absolute" style="background: rgba(255, 255, 255, 0.4); height: 100vh; z-index: 9999; top: 0; left: 0;" v-if="Loading.length > 0">
			<!-- <div class="spinner-grow text-main" style="width: 3rem; height: 3rem;" role="status">
				<span class="sr-only">Loading...</span>
			</div> -->

			<div class="lds-ellipsis">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>

			<!-- <b-spinner label="Spinning" variant="main" style="width: 3rem; height: 3rem;"></b-spinner> -->
		</div>

		<Confirm :model.sync="Confirm.show" :title="Confirm.title" :msg="Confirm.msg" @okFunc="Confirm.okFunc" v-if="Confirm.show"></Confirm>
	</div>
</template>

<script>
	import Confirm from "@/components/Modal/Confirm";
	export default {
		data() {
			return {};
		},

		async mounted() {
			// 重整
			// await this.$store.dispatch("Member/checkUser");

			// 載入語系
			await this.$store.dispatch("Public/getLanguage", process.env.VUE_APP_LANGUAGE);

			// 載入共用參數
			if (this.$store.state.Member.Login.status) {
				this.$store.dispatch("Public/getPublicData");
			}
		},

		computed: {
			Loading: {
				get() {
					return this.$store.state.Public.Loading;
				},
			},

			alert: {
				get() {
					return this.$store.state.Public.alert;
				},
			},

			Confirm: {
				get() {
					return this.$store.state.Public.Confirm;
				},
			},
			loginstatus: {
				get() {
					return this.$store.state.Member.Login.status;
				},
			},
		},

		watch: {
			loginstatus(newval, oldval) {
				console.log(newval, oldval);
				if (newval) {
					this.$store.dispatch("Public/getPublicData");
				}
			},
		},

		components: {
			Confirm,
		},
	};
</script>

<style lang="scss">
	@import "./style/color";

	.lds-ellipsis {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
	}

	.lds-ellipsis div {
		position: absolute;
		top: 33px;
		width: 13px;
		height: 13px;
		border-radius: 50%;
		background: $webMainLight;
		animation-timing-function: cubic-bezier(0, 1, 1, 0);
	}

	.lds-ellipsis div:nth-child(1) {
		left: 8px;
		animation: lds-ellipsis1 0.6s infinite;
	}

	.lds-ellipsis div:nth-child(2) {
		left: 8px;
		animation: lds-ellipsis2 0.6s infinite;
	}

	.lds-ellipsis div:nth-child(3) {
		left: 32px;
		animation: lds-ellipsis2 0.6s infinite;
	}

	.lds-ellipsis div:nth-child(4) {
		left: 56px;
		animation: lds-ellipsis3 0.6s infinite;
	}

	@keyframes lds-ellipsis1 {
		0% {
			transform: scale(0);
		}

		100% {
			transform: scale(1);
		}
	}

	@keyframes lds-ellipsis3 {
		0% {
			transform: scale(1);
		}

		100% {
			transform: scale(0);
		}
	}

	@keyframes lds-ellipsis2 {
		0% {
			transform: translate(0, 0);
		}

		100% {
			transform: translate(24px, 0);
		}
	}
</style>
