import http from "../support";
export default {
  getWebImage(website) {
    return http.post("Pub/getWebImage", {
      website: website
    });
  },

  autoSave(table, data) {
    return http.post("Pub/autoSave/" + table, data);
  },

  getData(table, search) {
    return http.post("Pub/getdata/" + table, search);
  },

  getMulitData(search) {
    return http.post("Pub/getMulitData", {
      data: search
    });
  },

  setMoveTime(temp) {
    return http.post("Schedule/setMoveTime", temp, {}, null, 0, false);
  },

  saveFavoriteFolder(data) {
    return http.post("Schedule/saveFavoriteFolder", data);
  },

  getField(folder, page = 0, pagenumber = 20) {
    return http.post(`Pub/getField/${page}/${pagenumber}`, {
      folder: folder
    });
  },

  saveFolder(data) {
    return http.post("Pub/saveFolder", data);
  },

  insertPointImage(data) {
    return http.post("Pub/insertPointImage", data);
  },

  insertSystemImage(data) {
    return http.post("Pub/insertSystemImage", data);
  },

  moveField(data) {
    return http.post("Pub/moveField", data);
  }
}
