<template>
	<div :id="id">
		<div class="m-edit-input d-flex align-items-center rounded-pill px-4" :class="{ error: error.use && !error.status }">
			<div class="mr-2 fs-4" :class="{ 'text-orange': error.use && !error.status, 'text-main': !error.use || error.status }" v-if="icon.show">
				<i :class="icon.icon"></i>
			</div>

			<div class="flex-fill d-flex align-items-center position-relative">
				<div class="flex-fill form-floating pr-4">
					<label class="m-small-1 text-muted m-0">{{ title }}<span class="text-danger ml-1" v-if="cantNull">*</span></label>

					<select class="w-100 form-select m-select px-0" v-model="getValues" @blur="blurInput">
						<option value="" disabled>請選擇</option>
						<option :value="option.values" v-for="option in getOption">{{ option.name }}</option>
					</select>
				</div>

				<span class="position-absolute text-orange" style="right: 0.3rem;" v-if="error.use && !error.status">
					<i class="bi bi-exclamation-circle"></i>
				</span>
			</div>
		</div>
		<slot name="errorBox" v-if="error.use">
			<div class="d-flex align-items-center px-4" style="min-height: 2rem;">
				<span class="text-fill text-orange small" v-if="!error.status">{{ error.msg || "錯誤回報" }}</span>
			</div>
		</slot>
	</div>
</template>

<script>
export default {
	data() {
		const random = Math.random() * 9999999999;
		const id = "editInput_" + random;
		return {
			id: id,
		};
	},

	props: {
		cantNull: {
			type: Boolean,
			default: false,
		},
		model: {
			type: String,
		},
		option: {
			type: [Object, Array],
			default: () => {
				return [];
			},
		},
		icon: {
			type: Object,
			default: () => {
				return { show: false, icon: "" };
			},
		},
		title: {
			type: String,
		},
		error: {
			type: Object,
			default: () => {
				return {
					use: false,
					status: true,
					msg: "錯誤回報",
				};
			},
		},
	},

	computed: {
		getValues: {
			get() {
				return this.model || "";
			},
			set(values) {
				this.$emit("update:model", values);
			},
		},
		getOption: {
			get() {
				return this.option;
			},
			set(values) {
				console.log(values);
			},
		},
	},

	methods: {
		blurInput() {
			this.$emit("blurFunc");
		},
	},

	watch: {
		model: {
			handler(newVal, oldVal) {
				this.$emit("changeFunc", newVal, oldVal);
			},
		},
	},
};
</script>
