import store from "@/store"

export default {
  capitalize: (value) => {
    if (!value) return '';
    value = value.toString();
    return value.charAt(0).toUpperCase() + value.slice(1)
  },
  filter: (value, where) => {
    const typea = typeof (where);
    const result = value.filter(function (item, keys) {

      if (typea == "string") {
        return item.indexOf(where) !== -1;
      } else {

        const op = $.map(where, (iit, key) => {

          return item[key] == iit;
        }).filter((ite) => {
          return ite;
        }).length;

        return op > 0;

      }
    });
    return result;
  },
  changelang: (value, field) => {
    let result = (store.state.Public.publang[field]) ? store.state.Public.publang[field][value] :
      "";
    return result || value;
  },
  currency(value) {
    console.log("value");
    if (value != null) {
      value = value.toString().replace(/^(-?\d+?)((?:\d{3})+)(?=\.\d+$|$)/, function (all, pre,
        groupOf3Digital) {
        return pre + groupOf3Digital.replace(/\d{3}/g, ',$&');
      });
    }

    return value;
  },

  fixed(value, num = 0) {
    return value.toFixed(num);
  },

  numberFormat(value, num = 0) {

  },

  sum(value) {
    return _.reduce(value, (sum, item, key) => {
      sum += isNaN(parseInt(item)) ? 0 : parseInt(item);
      return sum
    }, 0);
  }
}
