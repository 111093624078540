<template>
	<div>
		<div class="w-100 mb-2">
			<div class="w-100 p-2 border rounded m-html" style="min-height: 62px;" v-html="text" v-if="!edit"></div>

			<div class="d-flex flex-column" v-else>
				<b-form-textarea :id="id" v-model="text" max-rows="20" no-resize v-if="!htmlEdit"></b-form-textarea>
				<Editor v-model="text" v-else></Editor>
			</div>
		</div>

		<div class="w-100 d-flex align-items-center justify-content-end">
			<b-button class="mr-2" variant="outline-main" size="sm" @click="editHtml" v-if="edit">
				<span v-if="!htmlEdit">HTML編輯</span>
				<span v-else>文字編輯</span>
			</b-button>

			<b-button variant="outline-main" size="sm" @click="editText">
				<span v-if="!edit">編輯</span>
				<span v-else>完成</span>
			</b-button>
		</div>
	</div>
</template>

<script>
import Editor from "@/components/Tinymce.vue";

export default {
	data() {
		return {
			id: "text_" + Math.floor(Math.random() * 1000000),
			edit: false,
			htmlEdit: false,
		};
	},

	computed: {
		text: {
			get() {
				return this.$props.model;
			},

			set(data) {
				this.$emit("update:model", data);
			},
		},
	},

	methods: {
		editText() {
			this.edit = !this.edit;

			if (this.edit) {
				setTimeout(() => {
					document.querySelector("#" + this.id).focus();
				}, 500);
			}
		},

		editHtml() {
			this.htmlEdit = !this.htmlEdit;
		},
	},

	props: {
		model: {
			type: String,
		},
	},

	components: {
		Editor,
	},
};
</script>
